/* For seats.io seat planner */
#chart {
    height: 100%;
}

.seats-expanded {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000000;
    display: flex;
    flex-direction: column;
}