@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    body {
      @apply tw-font-sans;
      @apply tw-text-legacy-2;
    }
}

@layer utilities {
  .border-spacing-0 {
    border-spacing: 0;
  }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
   opacity: 1;
}